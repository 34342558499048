$primary-text: #092233;
$secondary-text: #578095;
$secondary-background: rgba(177, 217, 228, 0.50);
$primary-background: #F6FEFF;
$accent-color: #B1D9E4;
$discount-color: rgba(9, 34, 51, 0.50); 
$image-shadow-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
$box-shadow: 5px 5px 10px 4px rgba(0, 0, 0, 0.25);
$card-shadow-filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25));
$input-shadow: 2px 5px 8px 0px rgba(0, 0, 0, 0.25);
$font: 'Ubuntu Mono';
$desktop-breakpoint: 1330px;