@use './variables' as*;

//edit these later


//desktop viewport mixin, refer to vriables for breakpoints
@mixin desktop {
    @media (min-width: $desktop-breakpoint){
        @content;
    }
}


//styling for flexbox positioning
@mixin flex($align: normal, $justify:normal, $direction:row, $wrap: nowrap) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    flex-direction:$direction;
    flex-wrap:$wrap;
}


//styling for fonts
@mixin font($size:1rem, $weight: 400, $color:$primary-text, $spacing:0, $transform: uppercase) {
    font-family: $font;
    font-size: $size;
    font-weight: $weight;
    letter-spacing: $spacing;
    text-transform: $transform;
    color: $color;
    letter-spacing: $spacing;
}