@use '../../styles/variables' as*;
@use '../../styles/mixins' as *;


.prices {
    background: $primary-background;
    padding: 6.25rem 0;
    @include flex($direction: column, $justify: center, $align:center);
    @include desktop{
        padding:3.25rem 10rem 6.25rem 10rem;
        @include flex($direction:column)
    }
    &__cards {
        @include flex($direction: column);
        row-gap: 4rem;
        @include desktop {
            @include flex($justify:space-between);
            column-gap: 6.875rem;
        }
        
    }
    &__title {
        margin: 0;
        @include font($size: 1.75rem, $transform:none, $weight:700, $spacing: 2px);
        margin-bottom: 3rem; 
        @include desktop {
            @include font($size: 2.5rem, $transform:none, $weight:700, $spacing: 2px);
            margin-bottom: 6.25rem; 
        }
        
    }
}