@use '../../styles/variables' as*;
@use '../../styles/mixins' as *;




.contact {
    background: $secondary-background;
    @include flex($direction: column, $justify: center, $align: center);
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem; 
    @include desktop {
        padding: 6.25rem 10rem;
        @include flex($justify: space-between);
    }
    &__left{
        @include flex($direction: column, $justify:center, $align: center);
        margin-bottom: 2rem;
        row-gap: 2rem;
        @include desktop {
            @include flex($direction: column);
            align-items: flex-start;
        row-gap: 3.75rem;
        margin-right: 12.5rem;
        }

    }
    &__title{
        margin-top:2rem;
        margin-bottom: 0;
        @include font($transform: none, $weight:700, $size: 2rem, $spacing:2px);
        @include desktop {
            margin:0;
            text-align: left;
        @include font($transform: none, $weight:700, $size: 2.5rem, $spacing:2px);
        }
        
    }
    &__paragraph {
        margin:0;
        margin-bottom:2rem;
        @include font($transform: none, $size: 1rem, $color: $secondary-text);
        width: 18.9375rem;
        @include desktop {
            margin:0;
            width: 21.875rem;
            @include font($transform: none, $size: 1.25rem, $color: $secondary-text);
            text-align: left;
        }
    }
    &__form {
        @include flex($direction:column);
        row-gap: 3.75rem;
    }
    &__input {
        width: 19.125rem;
        height: 3.4375rem;
        border: none;
        border-radius: 38px;
        padding-left: 2rem;
        @include desktop {
            width: 34.375rem;
        }
        &::placeholder{
            @include font($size: 1rem, $weight:700, $color: $secondary-text, $transform: uppercase);
            @include desktop {
                @include font($size: 1.5rem, $weight:700, $color: $secondary-text, $transform: uppercase);
            }
        }
        &:focus{
            outline: none;
            border: 4px solid $secondary-text;
        }
        &--textarea {
            resize: none;
            padding-top:1rem;
            height: 15.625rem;
        }
    }
    &__submit {
        height: 2.5rem;
        @include flex($align:center, $justify: center);
        padding: 0.625rem 0.75rem;
        @include font($size: 1.25rem, $weight:700, $spacing: 3.8px);
        text-transform: uppercase;
        border: none;
        border-radius:20px;
        background: $secondary-text;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        box-shadow: $box-shadow;
        &:hover {
            color: $accent-color;
        }
        width:100%;
        @include desktop{
            align-self: flex-end;
            width: 9.6875rem;
        }
    }
    &__link{
        text-decoration: none; 
        color: $primary-text
    }
}